import React from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';

const Book = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderRadius: 6,
                margin: '0px 12px 18px 0px',
            }}
            onClick={props.onClick}
        >
            <Box style={{ boxShadow: '0px 1px 6px #14193d6b', borderRadius: 6 }}>
                <Image
                    sx={{ borderRadius: 6 }}
                    loader={() => props.data.cover.path}
                    src={props.data.cover.path}
                    alt="news"
                    width={2}
                    height={3}
                    priority
                    layout="responsive"
                    objectFit="cover"
                />
            </Box>
            <Typography variant="h6" sx={{ marginTop: 1 }}>
                {props.data.name}
            </Typography>
            <Typography variant="subtitle1">{props.data.authorName}</Typography>
        </Box>
    );
};

Book.defaultProps = {
    data: {
        name: 'Нэр',
        authorName: 'Зохиолч',
        year: 'Хэвлэгдсэн он',
        price: '10000',
        cover: '/banner/banner1.png',
    },
    onClick: null,
    userType: 'student',
};

export default Book;
