import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';

// utils
import useWindowSize from 'utils/useWindowSize';

const useStyles = makeStyles()(() => {
    return {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '30px 3px',
        },
        rootSmall: {
            // width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '30px 3px',
        },
        line: {
            height: 1,
            width: '100%',
            margin: 'auto',
            background: '#e9e9e9',
            margin: '0px 10px',
        },
        headerName: {
            color: '#222C3D',
            width: 'max-content',
        },
        headerNameSmall: {
            color: '#222C3D',
            width: 'fit-content',
        },
        add: {
            width: 'fit-content',
            backgroundColor: '#185CCC',
            padding: '5px 18px',
            borderRadius: 8,
            fontWeight: 600,
            color: 'white',
            marginLeft: 5,
            '&:hover': {
                backgroundColor: '#114499',
            },
        },
        more: {
            color: '#185CCC',
            margin: 4,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: '#f0f8ff00',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: '#d9e6ff',
                boxShadow: 'none',
            },
        },
    };
});

export const ToolBar = (props) => {
    const { classes } = useStyles();
    const { sizes } = useWindowSize();

    return (
        <>
            {sizes.sm ? (
                <Box className={classes.rootSmall}>
                    <Box className={classes.headerNameSmall}>
                        <Typography className={classes.headerNameSmall} variant="h3">
                            {props.name}
                        </Typography>
                    </Box>
                    {props.onAdd && (
                        <Box>
                            <Button
                                onClick={props.onAdd}
                                variant="contained"
                                className={classes.add}
                            >
                                {props.buttonTitle}
                            </Button>
                        </Box>
                    )}
                    {props.onStudent && (
                        <Box>
                            <Button
                                onClick={props.onStudent}
                                variant="contained"
                                className={classes.add}
                            >
                                Сурагчид
                            </Button>
                        </Box>
                    )}
                    {props.onTarget && (
                        <Box>
                            <Button
                                onClick={props.onTarget}
                                variant="contained"
                                className={classes.add}
                            >
                                Даалгавар
                            </Button>
                        </Box>
                    )}
                    {props.onMore && (
                        <Box>
                            <Button
                                onClick={props.onMore}
                                variant="contained"
                                className={classes.more}
                                type="submit"
                            >
                                Дэлгэрэнгүй
                            </Button>
                        </Box>
                    )}
                    {props.onTab && <Tab value="two" label="Item Two" />}
                </Box>
            ) : (
                <Box className={classes.root}>
                    <Box className={classes.headerName}>
                        <Typography className={classes.headerName} variant="h3">
                            {props.name}
                        </Typography>
                    </Box>
                    <Box className={classes.line}></Box>
                    {props.onAdd && (
                        <Box>
                            <Button
                                onClick={props.onAdd}
                                variant="contained"
                                className={classes.add}
                            >
                                {props.buttonTitle}
                            </Button>
                        </Box>
                    )}
                    {props.onStudent && (
                        <Box>
                            <Button
                                onClick={props.onStudent}
                                variant="contained"
                                className={classes.add}
                            >
                                Сурагчид
                            </Button>
                        </Box>
                    )}
                    {props.onTarget && (
                        <Box>
                            <Button
                                onClick={props.onTarget}
                                variant="contained"
                                className={classes.add}
                            >
                                Даалгавар
                            </Button>
                        </Box>
                    )}
                    {props.onMore && (
                        <Box>
                            <Button
                                onClick={props.onMore}
                                variant="contained"
                                className={classes.more}
                                type="submit"
                            >
                                Дэлгэрэнгүй
                            </Button>
                        </Box>
                    )}
                    {props.onTab && <Tab value="two" label="Item Two" />}
                </Box>
            )}
        </>
    );
};

ToolBar.propTypes = {
    name: PropTypes.string,
    onAdd: PropTypes.func,
    onStudent: PropTypes.func,
    onTarget: PropTypes.func,
    onMore: PropTypes.func,
    onTab: PropTypes.func,
};

ToolBar.defaultProps = {
    name: 'hello',
    buttonTitle: 'Нэмэх',
};

export default ToolBar;
