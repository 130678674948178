import { get, post, put, destroy } from 'utils/fetchRequest';

export const getBooks = async (query) => {
    try {
        const result = await get('books' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getRecentBooks = async (query) => {
    try {
        const result = await get('books/recents' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getAuthedBooks = async (query) => {
    try {
        const result = await get('books/authed' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getPurchased = async (query) => {
    try {
        const result = await get('books/purchased' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getMyBooks = async (query) => {
    try {
        const result = await get('books/mybooks' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getOneBook = async (id) => {
    try {
        const result = await get('books/' + id);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const getBookPurchaseStatus = async (id) => {
    try {
        const result = await get('books/' + id + '/purchase');
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const createBook = async (data) => {
    try {
        const result = await post('books', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateBook = async (id, data) => {
    try {
        const result = await put(`books/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deleteBook = async (id) => {
    try {
        const result = await destroy(`books/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};
